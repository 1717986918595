body {
	margin: 0 auto;
}

.navBar {
	margin: 0;
	padding: 1rem;
	background-color: #4A304D;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: center;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.55);
}

.navLink:link, .navLink:visited {
	color: white;
	text-decoration: none;
}

.navLink {
	border-radius: 0.5rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.navLink.selected:link, .navLink.selected:visited {
	color: black;
	text-decoration: none;
}