body {
	font-family: 'Fira Code';
}

.button {
	padding: 0.5rem;
	background-color: #B17179;
	border-radius: 0.8rem;
	cursor: pointer;
}

.button:hover {
	background-color: #cc828a;
}

.button:active {
	background-color: #eaa5ac;
}

.button.selected {
	background-color: #FFB37B;
	box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.55);
}

.button.disabled {
	background-color: rgb(158, 158, 158) !important;
	color:rgb(95, 95, 95);
	cursor: default !important;
}

.flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}