.serverStatus {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1, h2 {
		margin-block-start: 0;
		margin-block-end: 0;
	}

	h1 {
		margin: 1rem;
	}

	h2 {
		margin: 0rem;
	}
}

.serverStatus.online h2 {
	color: rgb(133, 205, 125);
}

.serverStatus.offline h2 {
	color: rgb(255, 96, 96);
}

.listingInfo.listingInfoHidden {
	> *:not(.listingInfoHeader) {
		display: none;
	}

	.listingInfoHeader h1:before {
		content: '> ';
		font-size: 1.5rem;
		vertical-align: middle;
		font-weight: 100;
	}
}

.listingInfo.listingInfoVisible .listingInfoHeader {
	h1:before {
		content: 'v ';
		font-size: 1.5rem;
		vertical-align: middle;
		font-weight: 100;
	}
}

.listingInfoHeader {
	h1 {
		cursor: pointer;
		user-select: none;
	}
}

.manualOverride.manualOverrideHidden {
	:not(h1) {
		display: none;
	}

	h1:before {
		content: '> ';
		font-size: 1.5rem;
		vertical-align: middle;
		font-weight: 100;
	}
}

.manualOverride.manualOverrideVisible {
	h1:before {
		content: 'v ';
		font-size: 1.5rem;
		vertical-align: middle;
		font-weight: 100;
	}
}

.manualOverride {
	text-align: center;

	h1 {
		cursor: pointer;
		user-select: none;
	}
}

#btnManualOverride {
	margin-top: 1rem;
	margin-left: auto;
	margin-right: auto;
	max-width: fit-content;
}

.listingInfo {
	margin-top: 2rem;
	text-align: center;
}

.listingInfoHeader {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

#turnipExchangeLink:link, #turnipExchangeLink:visited {
	color: black;
}

.listingInfoHeader #turnipCode {
	margin-left: 0.5rem;
}

#btnRefresh {
	cursor: pointer;
	margin-left: 1rem;
}

#btnUpdate {
	cursor: pointer;
	margin-left: 1rem;
}

.bauble {
	padding: 0.5rem;
	border-radius: 0.25rem;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.bauble-on {
	background-color: rgb(165, 255, 200);
	color: black;
}

.bauble-off {
	background-color: rgb(255, 163, 146);
	color: black;
}

.bauble-inactive {
	background-color: rgb(158, 158, 158);
	color:rgb(95, 95, 95);
}

.length {
	margin-left: 0.75rem;
}

.turnipsPriceWrapper {
	margin-top: 1rem;
}

#turnipsPrice {
	font-weight: bold;
}

#visitors {
	list-style-type: none;
	padding-inline-start: 0;
}

#visitors li {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.kick {
	margin-right: 0.75rem;
	color: rgb(255, 111, 111);
	font-weight: bold;
	font-size: 1.25rem;
	cursor: pointer;
}

.listingCommands {
	text-align: center;

	h1 {
		cursor: pointer;
		user-select: none;
	}
}

.commandsWrapper {
	font-size: 1rem;
	margin-bottom: 2rem;
}

.commandsWrapper .button {
	margin: 0.25rem;
}

.listingCommands.listingCommandsHidden {
	:not(h1) {
		display: none;
	}

	h1:before {
		content: '> ';
		font-size: 1.5rem;
		vertical-align: middle;
		font-weight: 100;
	}
}

.listingCommands.listingCommandsVisible {
	h1:before {
		content: 'v ';
		font-size: 1.5rem;
		vertical-align: middle;
		font-weight: 100;
	}
}

.commandPairRow {
	margin-top: 0.75rem;
}

.commandPairRow input, .commandPairRow textarea {
	margin-left: 1rem;
}

#btnDestroyIsland {
	background-color: rgb(255, 103, 103);
}

#inputDescription {
	width: 300px;
	height: 150px;
}

#btnUpdate{
	margin-top: 1rem;
	margin-left: auto;
	margin-right: auto;
	max-width: fit-content;
	margin-bottom: 2rem;
}

#messageText {
	width: 300px;
	height: 80px;
}

#btnSendMessage {
	max-width: fit-content;
	margin: 1rem auto;
}